import axiosInstance from '../utils/axiosInstance';

async function UploadImage(file, variant = 'blog') {
  const authToken = localStorage.getItem('_auth');

  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axiosInstance.post(
      '/api/upload_image/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`
        }
      },
      {withCredentials: false}
    );

    console.log('Upload success:', response.data);
    const imageUrl = `/images/${response.data.cloudflare_id}/${variant}`;
    return imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
}

export default UploadImage;
